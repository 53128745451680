import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './home1.css'; 

export const Home1 = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div id="home1" className="home1-section">
      <video autoPlay muted loop className="background-video">
        <source src="/vid/3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content-overlay" data-aos="fade-up">
        <h1 className="text-center" data-aos="fade-down">Mazooba</h1>
        <h2 className="text-center" data-aos="fade-up">We seek to pursue digital/smart agriculture transformation, e-farmer financing, online commodity trading, and exportation of value added produce</h2>
      </div>
    </div>
  );
};
