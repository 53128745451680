import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./maize.css";

const items = [
  { id: 1, img: "img/gallery/ma.1.jpeg", title: "Maize", text: "Maize" },
  { id: 2, img: "img/gallery/ma.2.jpeg", title: "Maize", text: "Maize" },
  { id: 3, img: "img/gallery/ma.4.jpg", title: "Maize", text: "Maize" },
  
  // Add more items as needed
];

export const Maize = () => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [modalImage, setModalImage] = useState(null);

  const offset = (currentPage - 1) * itemsPerPage;
  const currentItems = items.slice(offset, offset + itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleImageClick = (img) => {
    setModalImage(img);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  return (
    <div className="tm-page-wrap mx-auto">
      <div className="container-fluid">
        <div id="content" className="mx-auto tm-content-container">
          <main>
            <div className="row">
              <div className="col-12">
                <h2 className="tm-page-title mb-4">Maize Projects</h2>
                <div className="tm-categories-container mb-5">
                  <h3 className="tm-text-primary tm-categories-text">Categories:</h3>
                  <ul className="nav tm-category-list">
                    <li className="nav-item tm-category-item">
                      <NavLink to="/gallery" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>All</NavLink>
                    </li>
                    <li className="nav-item tm-category-item">
                      <NavLink to="/irrigation" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>Irrigation</NavLink>
                    </li>
                    <li className="nav-item tm-category-item">
                      <NavLink to="/manure" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>Manure</NavLink>
                    </li>
                    <li className="nav-item tm-category-item">
                      <NavLink to="/coffee" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>Coffee</NavLink>
                    </li>
                    <li className="nav-item tm-category-item">
                      <NavLink to="/livestock" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>Livestock</NavLink>
                    </li>
                    <li className="nav-item tm-category-item">
                      <NavLink to="/maize" className={({ isActive }) => `nav-link tm-category-link ${isActive ? "active" : ""}`}>Maize</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row tm-catalog-item-list" id="irrigation-container">
              {currentItems.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 tm-catalog-item">
                  <div className="position-relative tm-thumbnail-container" onClick={() => handleImageClick(item.img)}>
                    <img src={item.img} alt={item.title} className="img-fluid tm-catalog-item-img" />
                  </div>
                  <div className="p-4 tm-bg-light tm-catalog-item-description">
                    <h3 className="tm-text-primary mb-3 tm-catalog-item-title">{item.title}</h3>
                    <p className="tm-catalog-item-text">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>

            {modalImage && (
              <div className="modal" onClick={handleCloseModal}>
                <span className="close">&times;</span>
                <div className="modal-content">
                  <img src={modalImage} alt="Modal" className="modal-image" />
                </div>
              </div>
            )}

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(items.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-center"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
            />
          </main>
        </div>
      </div>
    </div>
  );
};
