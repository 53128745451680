import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const upcomingProject = {
  title: "Digital Agriculture Commodity Exchange (DACE)",
  description: "We are thrilled to announce our upcoming project, the Digital Agriculture Commodity Exchange (DACE). Stay tuned for more details!",
  launchDate: "2025-19-01",
  imageUrl: "/img/dace.png",
};

export const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const greenStyle = { color: "#28a745" }; // Green color

  return (
    <div className="container my-5">
      <h2 className="mb-4" style={greenStyle} data-aos="fade-up">Our Projects</h2>
      <p data-aos="fade-up">
        Welcome to our project showcase! Here, we highlight our recent and ongoing initiatives aimed at revolutionizing various sectors. Stay tuned to learn more about our exciting projects and their impact.
      </p>

      <h2 className="mt-5" style={greenStyle} data-aos="fade-up">Upcoming Project</h2>
      <div className="row mt-4">
        <div className="col-md-6" data-aos="fade-right">
          <h3>{upcomingProject.title}</h3>
          <p>{upcomingProject.description}</p>
          <p><strong>Launch Date:</strong> {upcomingProject.launchDate}</p>
        </div>
        <div className="col-md-6" data-aos="fade-left">
          <img src={upcomingProject.imageUrl} className="img-fluid" alt={upcomingProject.title} />
        </div>
      </div>
    </div>
  );
};
