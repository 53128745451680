import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Header } from "./components/Home/header";
import { Features } from "./components/feature/features";
import { Home1 } from "./components/home1/home1";
import { Services } from "./components/services/services";
import { Testimonials } from "./components/testimonials/testimonials";
import { OurProcess } from "./components/process/process";
import { Contact } from "./components/contact/contact";
import { Faqs } from "./components/faqs/faqs";
import { Gallery } from "./components/gallery/gallery";
import { Coffee } from "./components/coffee/coffee";
import { Maize } from "./components/maize/maize";

import { Livestock } from "./components/livestock/livestock";
import { Irrigation } from "./components/Irrigation/irrigation";
import { WhoWeAre } from "./components/whoweare/whoweare";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import { Values } from "./components/Value/values";
import { Products } from "./components/products/products"; // Import Products component
import { Projects } from "./components/projects/projects"; // Import Projects component
import { Manure } from "./components/manure/manure";

const AppRoutes = ({ landingPageData }) => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={300} classNames="fade">
        <Routes location={location}>
          {/* Route to the WhoWeAre page */}
          <Route path="/whoweare" element={<WhoWeAre data={landingPageData.WhoWeAre} />} />

          {/* Main route with all other content */}
          <Route
            path="/"
            element={
              <>
                <Header data={landingPageData.Header} />
                <OurProcess data={landingPageData.OurProcess} />
                <Values /> {/* Added Values component here */}
                <Features data={landingPageData.Features} />
                <Home1 data={landingPageData.Home1} />
                <Products /> {/* Added Products route */}

                <Testimonials data={landingPageData.Testimonials} />
                <WhatsAppButton />
              </>
            }
          />
          <Route path="/contact" element={<Contact data={landingPageData.Contact} />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/services" element={<Services data={landingPageData.Services} />} />
          <Route path="/projects" element={<Projects />} /> {/* Added Projects route */}  
          <Route path="/gallery" element={<Gallery />} /> 
          <Route path="/irrigation" element={<Irrigation />} /> 
          <Route path="/manure" element={<Manure />} /> 
          <Route path="/coffee" element={<Coffee />} /> 
          <Route path="/livestock" element={<Livestock />} /> 
          <Route path="/maize" element={<Maize />} /> 


        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AppRoutes;
