import React, { useState } from "react";
import './process.css';

export const OurProcess = () => {
  const [modalImage, setModalImage] = useState(null);

  const categories = [
    { img: "img/categories_img_01.jpg", name: "Category 1", details: "Reliable water for crops through efficient irrigation, reducing wastage and boosting yields." },
    { img: "img/categories_img_02.jpg", name: "Category 2", details: "Provides meat, milk, and leather. Sustainable practices enhance soil fertility and ensure animal health." },
    { img: "img/categories_img_03.jpg", name: "Category 3", details: "Coffee is the main crop, but members can intercrop it with passion fruits and bananas, improving cash flow and maximizing land use and yields" },
  ];

  const openModal = (img) => {
    setModalImage(img);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="categories">
      <div className="container">
        <div className="row">
          {categories.map((category, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={index} data-aos="fade-up">
              <div className="box bounce-animation">
                <img className="img-fluid" src={category.img} alt={`${category.name}`} />
                <div className="details">
                  <p>{category.details}</p>
                  <button className="view-btn" onClick={() => openModal(category.img)} aria-label={`View ${category.name}`}>
                    <span role="img" aria-label="eye">👁️</span>
                  </button>
                </div>
                <button className="btn hvr-hover" aria-label={`Button for ${category.name}`}>{category.name}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={modalImage} alt="Full size" />
          <button className="close-btn" onClick={closeModal}>X</button>
        </div>
      )}
    </div>
  );
};
