import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './header.css'; // Your custom styles

export const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duration of animation
    });
  }, []);

  const slides = [
    {
      image: '/img/intro-bg1.jpg',
      text: "Coffee Seedlings.",
      title: "Nurturing Growth",
      subtitle: "From Seed to Harvest",
      description: "Our members believe in the power of nurturing coffee seedlings, committing to sustainable practices for a greener future.",
      cta: "Learn More"
    },
    {
      image: '/img/intro-bg2.jpg',
      text: "Apiculture.",
      title: "Beekeeping Excellence",
      subtitle: "Sustainability in Action",
      description: "alongside coffee farming, our members are dedicated to apiculture, ensuring the bees are well taken care of for a better environment and it helps in cash flow",
      cta: "Discover More"
    },
    {
      image: '/img/intro-bg3.jpg',
      text: "Organic Farming",
      title: "Organic Solutions",
      subtitle: "Healthy Soil, Healthy Crops",
      description: "Devoted to organic farming, our members ensure every crop is grown with care, believing in nourishing the soil for a better community.",
      cta: "Discover More"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    pauseOnHover: true
  };

  return (
    <section className="main">
      <div className="slider-container" data-aos="fade-up">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide" data-aos="fade-in">
              <img src={slide.image} alt={`Slide ${index + 1} - ${slide.title}`} className="image" />
              <div className="overlay"></div> {/* Overlay div */}
              <div className="image-data">
                <div className="left-content">
                  <h2>WELCOME TO</h2>
                  <h2>Mazooba Growers Cooperative Society Ltd</h2>
                </div>
                <div className="spacer"></div> {/* Spacer for space between content */}
                <div className="right-content">
                  <span className="text">{slide.text}</span>
                  <h1>{slide.title}</h1>
                  <h3>{slide.subtitle}</h3>
                  <p>{slide.description}</p>
                  <a href="#features" className="button">{slide.cta}</a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Header;
